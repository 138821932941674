<template>
  <v-app id="inspire">
    <template v-if="vistaAgenda == 1">
      <v-container grid-list-xs>
        <v-card class="elevation-12">
          <!--   <div id="contenedorImg">
          <img id="logoAlcaldia" src="../assets/recursos/neiva.jpeg" alt="" />
        </div> -->
          <v-toolbar color="#007831" dark flat>
            <v-toolbar-title><b>Reservar turnos</b></v-toolbar-title>
            <v-spacer></v-spacer>

            <img
              id="logoAlcaldia"
              src="../assets/recursos/logo_neiva.png"
              alt=""
            />
          </v-toolbar>

          <div style="padding:5%">
            <v-row>
              <v-col cols="12" sm="8" md="4">
                <v-select
                  v-model="dataEnviar.dependence_id"
                  :items="dependencies"
                  item-text="name"
                  item-value="id"
                  label="Dependencia"
                  @change="changedValue"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="8" md="4" v-if="services.length > 0">
                <v-select
                  v-model="dataEnviar.service_id"
                  :items="services"
                  item-text="name"
                  item-value="id"
                  label="Servicio"
                  required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="8" md="4">
                <v-select
                  v-model="dataEnviar.typedocument_id"
                  :items="typeDocuments"
                  item-text="name"
                  item-value="id"
                  label="Tipo de Documento"
                  required
                ></v-select>
              </v-col>

              <v-col cols="12" sm="8" md="4">
                <v-text-field
                  label="Documento"
                  type="tel"
                  v-model="dataEnviar.identification"
                  @keypress="isNumber($event)"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" sm="8" md="4" v-if="moreShifts">
                <v-select
                  v-model="dataEnviar.date_reservation"
                  :items="dateCustom"
                  item-text="day"
                  item-value="day"
                  label="Fechas"
                  required
                  @change="changedDay"
                ></v-select>
              </v-col>

              <v-col cols="12" sm="8" md="4" v-if="hoursByDay.length > 0">
                <v-select
                  v-model="dataEnviar.hour_reservation"
                  :items="hoursByDay"
                  item-text="hour"
                  item-value="hour"
                  label="Hora"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-checkbox
              v-model="checkbox"
              label="Terminos y Condiciones"
              required
            ></v-checkbox>
          </div>
          <!--  <pre>{{ hours }}</pre> -->
          <v-card-actions>
            <div style="text-align: center">
              <v-btn
                v-if="moreShifts"
                color="primary"
                elevation="2"
                @click="reservationCustom()"
                :disabled="
                  dataEnviar.dependencia_id == 0 ||
                    dataEnviar.tipodocumento_id == 0 ||
                    dataEnviar.identification == '' ||
                    dataEnviar.service_id == 0 ||
                    dataEnviar.date_reservation == 0 ||
                    dataEnviar.hour_reservation == 0 ||
                    !checkbox
                "
              >
                RESERVAR</v-btn
              >
            </div>
            <v-row style="width:100%; justify-content: center; margin: auto;">
              <v-btn
                v-if="!moreShifts"
                style="margin: 0px 10px"
                color="#347c01"
                dark
                @click="consultarAgenda()"
                :disabled="
                  dataEnviar.dependencia_id == 0 ||
                    dataEnviar.tipodocumento_id == 0 ||
                    dataEnviar.identification == '' ||
                    dataEnviar.service_id == 0 ||
                    !checkbox
                "
                >Consultar Agenda</v-btn
              >
              <v-btn
                color="blue"
                dark
                @click="SET_MODAL(2)"
                style="margin: 0px 10px"
                >Consultar Turno</v-btn
              >
              <!--   <v-btn color="dark" dark @click="(2)" style="margin: 0px 10px"
                >Consultar Horario por día</v-btn
              > -->
              <!--  <v-btn color="#bd0b01" dark> Cancelar</v-btn> -->
            </v-row>
          </v-card-actions>
        </v-card>
      </v-container>
    </template>

    <!--CONSULTAR TURNO POR CEDULA -->
    <template v-if="vistaAgenda == 2">
      <searchComponent></searchComponent>
    </template>

    <!-- MODAL INFO HORARIOS PARA RESERVA -->

    <div class="text-center">
      <v-dialog @input="closeModal()" v-model="dialog" width="500">
        <v-card>
          <v-toolbar color="#007831" dark flat class="flex text-center">
            <b>
              Puedes seleccionar uno de los siguientes horarios que se
              encuentran disponibles</b
            >
          </v-toolbar>

          <div style="height:1em"></div>

          <v-card-text style="text-align:center">
            <div row :key="index" v-for="(item, index) in hours">
              <label style="font-weight:600;color:#bd0b01">
                {{ new Date(item.fecha).toISOString().substr(0, 10) }}
                {{ item.hour }}
              </label>
              <input
                type="radio"
                :value="index"
                v-model="posicion"
                style="margin-left:10px"
              />
            </div>
          </v-card-text>

          <v-divider></v-divider>
          <div style="height:1em"></div>
          <div style="text-align: center">
            <v-btn color="primary" elevation="2" @click="reservation()">
              RESERVAR</v-btn
            >
          </div>

          <div style="text-align: center;padding:5%">
            O si lo prefieres programa tu visita en otra fecha y horario que más
            te convenga
          </div>

          <div style="text-align: center">
            <v-btn
              color="primary"
              elevation="2"
              @click="
                moreShifts = true;
                dialog = false;
              "
            >
              Ver más turnos</v-btn
            >
          </div>

          <!--  <span> {{ posicion }}</span> -->
          <!--  <div style="text-align: center;padding:3%">
            O si lo prefieres programa tu visita en otra fecha y horario que más
            te convenga
          </div>
 -->
          <v-card-actions style="text-align: center;">
            <!--  <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              Ver mas turnos
            </v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-app>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions, mapState, mapMutations } from "vuex";
import searchComponent from "../components/digiturno/Search";

export default {
  data() {
    return {
      dialog: false,
      email: "",
      select: "",
      checkbox: false,
      errorSolicitud: 0,
      prueba: false,
      horaReserva: false,
      posicion: 0,
      dataUser: {
        identification: "",
        typeDocument: 0,
      },
      // messageSolicitud:
      dataEnviar: {
        dependence_id: 0,
        typedocument_id: 0,
        identification: "",
        service_id: 0,
        date_reservation: "",
        hour_reservation: "",
      },
      hours: [],
      hoursByDay: [],
      dateCustom: [],
      selected: null,
      services: [],
      disableSelectService: true,
      moreShifts: false,
    };
  },
  components: { searchComponent },
  computed: {
    ...mapState("reservation", [
      "dependencies",
      "typeDocuments",
      "vistaAgenda",
      "hoursDay",
    ]),
  },
  methods: {
    ...mapMutations("reservation", ["SET_MODAL"]),
    ...mapActions("reservation", [
      "sendReservation",
      "getHours",
      "consultShifts",
      "getData",
      "getServicesByDependecie",
      "getDateCustom",
      "getHoursByDay",
    ]),
    validate() {},
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 9) {
        evt.preventDefault();
      } else {
        return true;
      }
      `enter code here`;
    },
    alertDisplay() {
      Swal.fire({
        title: "Error!",
        text: "Do you want to continue",
        icon: "error",
        confirmButtonText: "Cool",
      });
    },
    reservation() {
      this.swwetTimeAlert();
      console.log(`datos son:`);
      this.dataEnviar["hour_reservation"] = this.hours[this.posicion]["hour"];
      this.dataEnviar["date_reservation"] = this.hours[this.posicion]["fecha"];
      //console.log(this.dataEnviar);

      this.sendReservation(this.dataEnviar).then(() => {
        /* console.log("Respuesta es:");
        console.log(response); */
        this.cleanReservation();
        this.dialog = false;
        Swal.close();
        this.services = [];

        this.succesAlert();
      });
    },

    reservationCustom() {
      this.swwetTimeAlert();

      this.sendReservation(this.dataEnviar).then(() => {
        /* console.log("Respuesta es:");
        console.log(response); */
        this.cleanReservation();
        this.dialog = false;
        Swal.close();
        this.services = [];

        this.succesAlert();
      });
    },

    cleanReservation() {
      this.dataEnviar = {
        dependence_id: 0,
        typedocument_id: 0,
        identification: "",
        service_id: 0,
        date_reservation: "",
        hour_reservation: "",
      };
      this.checkbox = false;
      this.moreShifts = false;
      this.hoursByDay = [];
      this.getData();
      this.getDateCustom();
    },

    succesAlert() {
      Swal.fire({
        position: "middle",
        icon: "success",
        title: "Turno reservado exitosamente",
        showConfirmButton: false,
        timer: 2000,
      });
    },

    swwetTimeAlert() {
      let timerInterval;
      Swal.fire({
        title: "INFORMACIÓN",
        html: "Espere por favor...<b>",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          timerInterval = setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then(() => {});
    },

    consultarAgenda() {
      this.getHours(this.dataEnviar).then((response) => {
        //console.log("Respuesta es:");
        this.hours = [];
        this.hours = response.data.hours;
        //.log(response.data.hours);

        this.dialog = true;
      });
    },
    changedDay: function(value) {
      //this.selected = value;
      this.dataEnviar.day = value;
      this.getHoursByDay(this.dataEnviar).then((response) => {
        console.log("Respuesta horas por día");
        console.log(response);
        this.hoursByDay = response.data.hours;
      });
    },
    changedValue: function(value) {
      this.selected = value;
      this.dataEnviar.service_id = 0;
      this.getServicesByDependecie(this.selected).then((response) => {
        this.services = response.data.services;
      });
    },
    closeModal() {
      console.log("Modal cerrado");
    },
  },
  created() {
    this.getData();
    this.getDateCustom().then((response) => {
      console.log("llegaron:");
      console.log(response);
      this.dateCustom = response.data.days;
    });
  },
};
</script>

<style>
.container {
  /* background: orange; */
  justify-content: center !important;
  /*  
  display: flex;
  flex-wrap: wrap; */
  width: 80% !important;
  margin-top: 5%;
  padding: 5%;
}
.v-card {
  /*  height: 1300px  !important; 
  background: orange !important;*/
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#contenedorImg {
  text-align: center;
}
#logoAlcaldia {
  height: 50px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}
</style>
