<template>
  <div>
    <v-container grid-list-xs>
      <v-card class="elevation-12">
        <v-toolbar color="#007831" dark flat>
          <v-toolbar-title><b>Consultar turno</b></v-toolbar-title>
          <v-spacer></v-spacer>

          <img
            id="logoAlcaldia"
            src="../../assets/recursos/logo_neiva.png"
            alt=""
          />
        </v-toolbar>

        <div style="padding:5%">
          <v-row>
            <v-col cols="12" sm="8" md="4">
              <v-select
                v-model="dataUser.typeDocument"
                :items="typeDocuments"
                item-text="name"
                item-value="id"
                label="Tipo de Documento"
                required
              ></v-select>
            </v-col>

            <v-col cols="12" sm="8" md="4">
              <v-text-field
                label="Documento"
                type="tel"
                v-model="dataUser.identification"
                @keypress="isNumber($event)"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <!--  <pre>{{ typeDocuments }}</pre> -->
        <v-card-actions>
          <v-row style="width:100%; justify-content: center; margin: auto;">
            <v-btn
            style="margin-right:10px"
              color="#347c01"
              dark
              @click="shifts()"
              :disabled="
                dataUser.typeDocument == 0 || dataUser.identification == ''
              "
              >Consultar</v-btn
            >
            <v-btn color="#bd0b01" dark @click="SET_MODAL(1)">
              Cancelar</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>

    <!-- MODAL INFO HORARIOS PARA RESERVA -->

    <div class="text-center">
      <v-dialog @input="closeModal()" v-model="dialog" width="500">
        <v-card>
          <v-toolbar color="#007831" dark flat class="flex text-center">
            Tienes la siguiente visita programada
          </v-toolbar>

          <div style="height:1em"></div>

          <v-card-text
            style="text-align:center;"
            :key="index"
            v-for="(item, index) in dataShifts.reservations"
          >
            <!--  <div row :key="index" v-for="(item, index) in hours">
              <label style="font-weight:600;color:#bd0b01">
                {{ new Date(item.fecha).toISOString().substr(0, 10) }}
                {{ item.hour }}
              </label>
              <input
                type="radio"
                :value="index"
                v-model="posicion"
                style="margin-left:10px"
              />
            </div> -->

            <v-row
              style="text-align:left;border-radius:10px;
              margin:0.2em 0;box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);padding:2%"
            >
              <v-col cols="12" sm="6" md="6" style="height:2em;padding:0"
                ><b> Tipo de documento</b>
              </v-col>
              <v-col cols="12" sm="6" md="6" style="height:2em;padding:0"
                ><b>Número de identificación </b>
              </v-col>
              <v-col cols="12" sm="6" md="6" style="height:2em;padding:0">
                {{ dataShifts.typedocument_id }}
              </v-col>
              <v-col cols="12" sm="6" md="6" style="height:2em;padding:0">
                {{ dataShifts.identification }}
              </v-col>

              <v-col cols="12" sm="6" md="6" style="height:2em;padding:0"
                ><b> Dependencia</b>
              </v-col>
              <v-col cols="12" sm="6" md="6" style="height:2em;padding:0"
                ><b>Servicio </b>
              </v-col>
              <v-col cols="12" sm="6" md="6" style="height:2em;padding:0">
                {{ item.services.dependencies.name }}
              </v-col>
              <v-col cols="12" sm="6" md="6" style="height:2em;padding:0">
                {{ item.services.name }}
              </v-col>

              <v-col cols="12" sm="6" md="6" style="height:2em;padding:0"
                ><b> Fecha</b>
              </v-col>
              <v-col cols="12" sm="6" md="6" style="height:2em;padding:0"
                ><b>Hora </b>
              </v-col>
              <v-col cols="12" sm="6" md="6" style="height:2em;padding:0">
                {{ item.date_reservation }}
              </v-col>
              <v-col cols="12" sm="6" md="6" style="height:2em;padding:0">
                {{ item.hour_reservation }}
              </v-col>
              <v-col cols="12" sm="6" md="6" style="height:2em;padding:0">
                <v-btn color="#bd0b01" text > CANCELAR TURNO</v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>
          <div style="height:1em"></div>

          <!--  <span> {{ posicion }}</span> -->
          <!--  <div style="text-align: center;padding:3%">
            O si lo prefieres programa tu visita en otra fecha y horario que más
            te convenga
          </div>
 -->
          <v-card-actions style="text-align: center;">
            <v-row style="width:100%; justify-content: center; margin: auto;">
              <v-btn color="#347c01" @click="dialog = false" text
                >REGRESAR</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions, mapState,mapMutations } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      dataShifts: [],
      dataUser: {
        typeDocument: 0,
        identification: "",
      },
    };
  },
  computed: {
    ...mapState("reservation", ["typeDocuments",]),
  },
  methods: {
    ...mapMutations("reservation",["SET_MODAL"]),
    ...mapActions("reservation", ["getData", "isNumber", "consultShifts"]),
    succesAlert() {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Turno reservado exitosamente",
        showConfirmButton: false,
        timer: 2000,
      });
    },

    alertDisplay() {
      Swal.fire({
        title: "Información",
        text:
          "No se ha encontrado visita agendada previamente para el número de documento consultado.",
        icon: "info",
        confirmButtonText: "Aceptar",
      });
    },
    swwetTimeAlert() {
      let timerInterval;
      Swal.fire({
        title: "INFORMACIÓN",
        html: "Espere por favor...<b>",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          timerInterval = setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then(() => {});
    },
    shifts() {
      this.swwetTimeAlert();
      this.consultShifts(this.dataUser).then((response) => {
        console.log(response);
        this.dataShifts = [];
        if (response.data.shifts) {
          this.dataShifts = response.data.shifts;
          Swal.close();
          this.dialog = true;
        } else {
          Swal.close();
          this.alertDisplay();
        }
      });
    },
    closeModal() {
      console.log("Modal cerrado");
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode != 9) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },

  created() {
    this.getData();
  },
};
</script>
