import axios from "axios";

const state = {
  data: [],
  response: {},
  hoursDay: [],
  dependencies: [],
  typeDocuments: [],
  services: [],
  vistaAgenda: 1,
};

const actions = {
  async sendReservation(context, reservation) {
    console.log("Objeto es: ");
    console.log(reservation);
    return await axios.post("reservation", {
      dependence_id: reservation.dependence_id,
      typedocument_id: reservation.typedocument_id,
      identification: reservation.identification,
      service_id: reservation.service_id,
      date_reservation: reservation.date_reservation,
      hour_reservation: reservation.hour_reservation,
    });
  },

  async getHours(context, hour) {
    return await axios.post("horarios", {
      service_id: hour.service_id,
    });
  },
  async getHoursByDay(context, data) {
     return await axios.post("hoursByDay", {
      service_id: data.service_id,
      date_reservation: data.day,
    }); 
  },
  async consultShifts(context, shifts) {
    console.log("consultShifts es: ");
    return await axios.post("shiftsByIdentification", {
      identification: shifts.identification,
    });
  },

  async getData({ commit }) {
    const response = await axios.get("data");
    commit("SET_DATA", response);
  },

  async getServicesByDependecie(context, dependencie) {
    return await axios.post("servicesByDependencie", {
      dependence_id: dependencie,
    });
  },
  async getDateCustom() {
    return  await axios.get("getDateCustom");
  },
};

const mutations = {
  SET_RESPONSE(state, data) {
    state.response = data;
  },

  SET_DATA(state, payload) {
    state.dependencies = payload.data.dependencies;
    state.typeDocuments = payload.data.typeDocuments;
    console.log(payload.data.dependencies);
    console.log(payload.data.typeDocuments);
  },
 /*  SET_DATE_CUSTOM(state, payload) {
    state.dateCustom = payload.data.days;
  }, */
  SET_MODAL(state, payload) {
    state.vistaAgenda = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
