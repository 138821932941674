import axios from "axios"

const state = {
    users: [],
    data: [],
    response: {}
}

const actions = {
    async getList({commit}, [pageNumber, search]) {
        const response = await axios.get('user/users?page=' + pageNumber + '&search='+ search)
        if(response) {
            commit('SET_USERS', response.data.users.data)  
        }     
    }, 
    
    async getData({commit}) {
        const response = await axios.get('user/users/data?aplication=' + 1)
        if(response) {
            commit('SET_DATA', response.data) 
        }
    },  

    async saveUser({commit, dispatch}, user) {
        const response = await axios.post('user/users/store', {
            name: user.name, 
            email: user.email, 
            password: user.password,
            roles: user.checkedRole
        })
        commit('SET_RESPONSE', response.data.data)
        dispatch('getList', [0, ''])
    }
}

const mutations = {
    SET_USERS(state, data) {
        state.users = data
    }, 

    SET_RESPONSE(state, data) {
        state.response = data
    }, 

    SET_DATA(state, data) {
        state.data = data
    }
}

export default {
    namespaced: true, 
    state, 
    actions, 
    mutations
}