<template>
    <v-app id="inspire">
    <v-app id="inspire">
      <v-main>
        <v-container
          class="fill-height"
          fluid
        >
          <v-row
            align="center"
            justify="center"
          >
            <v-col
              cols="12"
              sm="8"
              md="4"
            >
              <v-card class="elevation-12">
                <v-toolbar
                  color="indigo darken-4"
                  dark
                  flat
                >
                  <v-toolbar-title>Formulario de Acceso</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-form>
                    <v-text-field
                      label="Login"
                      name="login"
                      prepend-icon="mdi-account"
                      type="text"
                      v-model="credentials.email"
                    ></v-text-field>
  
                    <v-text-field
                      label="Password"
                      name="password"
                      prepend-icon="mdi-lock"
                      type="password"
                      v-model="credentials.password"
                    ></v-text-field>
                    <div style="color: red; text-align: center">{{message}}</div>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="indigo darken-4" style="color: #fff" @click="login">Login</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-app>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data(){
        return {
            credentials: {
                email: '', 
                password: ''
            }
        }
    }, 

    computed: {
        ...mapState('login', ['message'])
    },

    methods: {
      login() {
        this.$store.dispatch('login/login', this.credentials)
        .then(() => {
          this.$router.push("/home")
        }).catch(() => {
          this.$store.commit('login/SET_MESSAGE','Autenticación, no autorizada!')
        })
      }
    }
}
</script>