import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'


axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const {
      config, 
      response: {status, data}
    } = error;

    const originalRequest = config
    console.log(originalRequest)
    // if (originalRequest.url.includes("refresh")) {
    //   return Promise.reject(error);
    // }

    if (status == 401 && data.message == 'Token has expired') {
      // store.dispatch('login/refresh')
      //   .then(() => {
      //     console.log("aquí")
      //   }).catch((e) => {
      //     console.log(e)
      //   })
      router.push("login");
    }

    // const requestSubscribers = new Promise(resolve => {
    //   subscribeTokenRefresh(() => {
    //     resolve(axios(originalRequest));
    //   });
    // });

    // // onRefreshed();
    // console.log(requestSubscribers)
    // return requestSubscribers;
  }
)

// function subscribeTokenRefresh(cb) {
//   subscribers.push(cb)
// }

// function onRefreshed(){
//   subscribers.map(cb => cb())
// }

require('./store/modules/Suscriber')
Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_SERVIDOR

store.dispatch('login/me', localStorage.getItem('spa_token'))
  .then(() => {
    new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  })


