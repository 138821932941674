import Vue from 'vue'
import Vuex from 'vuex'
import login from './modules/Login'
import user from './modules/User'
import reservation from './modules/Reservation'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    login,
    user,
    reservation
  }
})
